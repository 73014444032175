import React from 'react';
import NavLogo from '../../components.common/navbar/navlogo/NavLogo';
import media from '../../media';
import GuestFooter from '../../components.common/footer/guestfooter';

function HomePage() {
  return (
    <div className="h-95">
      <NavLogo isFixed />
      <div className="container d-flex h-100">
        <div className="align-self-center w-100">
          <div className="row">
            <div className="col-12 col-sm-8 col-md-6 mx-auto">
              <img className="img-fluid align-self-center mr-3" src={media.LOGO_DARK} alt="DIY.FUND" />
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 col-sm-8 col-md-6 mx-auto text-center">
              <div className="homepage-logo-text">
                where <span className="text-color-orange">individuals</span> invest <span className="text-color-lightblue">intelligently</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-8 col-md-6 mx-auto text-center">
              <h2>Coming Soon</h2>
            </div>
          </div>
          <div className="mt-5">
            <div className="text-center text-md">Award winning platform</div>
            <div className="text-center text-md">giving individuals the power</div>
            <div className="text-center text-md">to professionally manage their own investments.</div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <video id="homepage-bgvid" muted autoPlay loop tabIndex="0">
          <source src="https://media.diy.fund/intro/intro-bk.mp4" type="video/mp4" />
          <source src="https://media.diy.fund/intro/intro-bk.webm" type="video/webm" />
          <source src="https://media.diy.fund/intro/intro-back.png" />
        </video>
      </div>
      <div className="col-12 img-learnmore">
        <div
          style={{
            position: 'absolute',
            top: '-85px',
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 0,
            right: 0,
          }}
        >
          <img className="center-block" src="https://media.diy.fund/intro/intro-arrowdown.png" style={{ height: '40px' }} alt="more" />
        </div>
      </div>
      <div style={{ backgroundColor: '#fff' }}>
        <div className="intro-featuredin row no-gutters">
          <div className="col-12">
            <div
              style={{
                position: 'absolute',
                top: '-45px',
                marginLeft: 'auto',
                marginRight: 'auto',
                left: '0',
                right: '0',
              }}
            >
              <img className="img center-block" src="https://media.diy.fund/intro/featuredin-btn.png" style={{ height: '47px' }} alt="featured in" />
            </div>
          </div>
          <div className="col-12 col-md-1">&nbsp;</div>
          <div className="col-6 col-md-2">
            <a href="http://www.tradestreaming.com/announcement/the-2016-tradestreaming-awards-winners-best-investing-technology-and-fintech-investors" target="_blank" rel="noopener noreferrer">
              {' '}
              <img className="img center-block intro-featuredin-img" src="https://media.diy.fund/intro/featuredin-tradestreaming.png" alt="as seen in" />
            </a>
          </div>
          <div className="col-6 col-md-2">
            <a href="http://starterstudio.com/" target="_blank" rel="noopener noreferrer">
              {' '}
              <img className="img center-block intro-featuredin-img" src="https://media.diy.fund/intro/featuredin-starterstudio.png" alt="featured in Starter Studio" />{' '}
            </a>
          </div>
          <div className="col-6 col-md-2">
            <a href="https://www.benzinga.com/fintech/17/01/8952340/want-to-manage-your-portfolio-like-a-hedge-fund-with-fintech-you-can-do-it-you" target="_blank" rel="noopener noreferrer">
              {' '}
              <img className="img center-block intro-featuredin-img" src="https://media.diy.fund/intro/featuredin-benzinga.png" alt="featured in Benzina" />
            </a>
          </div>
          <div className="col-6 col-md-2">
            <a href="http://orlandotech.org/2015/09/10/industry-spotlight-financial-technology/" target="_blank" rel="noopener noreferrer">
              {' '}
              <img className="img center-block intro-featuredin-img" src="https://media.diy.fund/intro/featuredin-ota.png" alt="featured in Orlando Tech" />
            </a>
          </div>
          <div className="col-12 col-md-2">
            <a href="http://www.orlandosentinel.com/business/technology/tech_check/os-starter-studio-fourth-class-debut-20150713-post.html" target="_blank" rel="noopener noreferrer">
              {' '}
              <img className="img center-block intro-featuredin-img" src="https://media.diy.fund/intro/featuredin-sentinel.png" alt="featured in the Orlando Sentinel" />{' '}
            </a>
          </div>
        </div>
        <div className="container">
          <div className="intro-section row">
            <div className="offset-1 col-11 offset-sm-1 col-sm-5 offset-lg-1 col-lg-4" style={{ textAlign: 'center' }}>
              <div style={{ textAlign: 'left' }}>
                <div className="intro-h1">DESIGN YOUR PORTFOLIO</div>
                <div className="intro-h2">Create a living, breathing plan</div>
                <div className="intro-text">
                  {' '}
                  DIY.FUND helps you create and maintain a portfolio based on Modern Portfolio Theory (&quot;MPT&quot;), which is the framework for combining uncorrelated assets to generate the
                  highest returns possible at a given level of risk.
                </div>
                <div className="intro-h3"> We make it intuitive.</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 offset-lg-1 col-lg-6 float-md-right">
              <img className="img img-fluid float-md-right" src="https://media.diy.fund/intro/intro-mac.png" alt="screenshot" />
            </div>
          </div>
          <div className="container">
            <div className="intro-section row">
              <div className="col-6 col-sm-3">
                <img className="center-block intro-icon" src="https://media.diy.fund/intro/icon-compass.png" alt="screenshot" />
                <div className="intro-text-icontitle">Set your investment goals</div>
                <div className="intro-text-icon">From dividends to risk, you determine what is important to you.</div>
              </div>
              <div className="col-6 col-sm-3">
                <img className="center-block intro-icon" src="https://media.diy.fund/intro/icon-eye.png" alt="screenshot" />
                <div className="intro-text-icontitle">Monitor your portfolio</div>
                <div className="intro-text-icon">Time is valuable. Get alerted when your portfolio needs attention.</div>
              </div>
              {/* <div className="clearfix visible-xs-block">&nbsp;</div> */}
              <div className="col-6 col-sm-3">
                <img className="center-block intro-icon" src="https://media.diy.fund/intro/icon-bars.png" alt="screenshot" />
                <div className="intro-text-icontitle">Understand your risk</div>
                <div className="intro-text-icon">Get key risk metrics, and optimize your risk adjust return.</div>
              </div>
              <div className="col-6 col-sm-3">
                <img className="center-block intro-icon" src="https://media.diy.fund/intro/icon-person.png" alt="do it yourself" />
                <div className="intro-text-icontitle">Do it yourself</div>
                <div className="intro-text-icon">Professionals invest with the proper tools. Now you have them too.</div>
              </div>
            </div>
          </div>
          <div className="intro-section row">
            <div className="col-12 col-sm-5 col-md-5 col-lg-5" style={{ paddingLeft: '0px' }}>
              <img className="img img-fluid" src="https://media.diy.fund/intro/intro-statistics.png" alt="statistics" />
            </div>
            <div className="offset-1 col-10 offset-sm-1 col-sm-5 col-md-5 col-lg-4" style={{ textAlign: 'center' }}>
              <div style={{ textAlign: 'left' }}>
                <div className="intro-h1">OVERSEE YOUR INVESTMENTS</div>
                <div className="intro-h2">Understand Your Risk</div>
                <div className="intro-text">
                  {' '}
                  Your performance should be commensurate with your risk. That is how you optimally invest. <br /> We calculate this for you, help you understand it, and let you manage it.
                </div>
                <div className="intro-h3"> We make it easy.</div>
              </div>
            </div>
            <div className="col-1 col-sm-1 col-md-1 col-lg-1" />
          </div>
          <div className="intro-section row no-gutters">
            <div className="offset-1 col-11 offset-sm-1 col-sm-5 offset-md-1 col-md-5 offset-lg-1 col-lg-4 text-center">
              <div className="text-left">
                <div className="intro-h1">INVEST PROFESSIONALLY</div>
                <div className="intro-h2">Access the Right Tools</div>
                <div className="intro-text">
                  {' '}
                  Professional investing begins with setting goals and targets. Modern Portfolio Theory was designed to reduce your portfolio risk, while optimizing returns. Whether you are starting
                  out, or a seasoned investor, when it comes to investing, knowledge is certainly power.
                </div>
                <div className="intro-h3"> We give you control.</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 float-md-right" style={{ paddingRight: '0px' }}>
              <img className="img img-fluid float-md-right" src="https://media.diy.fund/intro/intro-allocationgraph.png" alt="allocation" />
            </div>
          </div>
          <div className="text-align:center">
            <hr className="intro-line" />
          </div>
          <div className="intro-section row">
            <div className="col-12 offset-sm-2 col-sm-7 col-md-6" style={{ marginBottom: '10px' }}>
              <div className="intro-h1">
                Benzinga Fintech awards 2017{' '}
                <a href="https://www.benzinga.com/fintech/17/05/9455991/your-2017-benzinga-fintech-award-winners" target="_blank" rel="noopener noreferrer">
                  Winner{' '}
                </a>
              </div>
              <div className="intro-h2">Levelling the Playing Field</div>
              <div className="intro-text"> DIY.FUND was honored to win &quot;Levelling the Playing Field&quot; at the prestigious 2017 Benzinga Fintech Awards in NYC.</div>
              <div className="intro-text"> Benzinga is an innovative financial media outlet that empowers investors with high-quality content that is coveted by Wall Street&apos;s top traders.</div>
            </div>
            <div className="col-12 col-sm-3 col-md-3">
              <img className="img img-fluid" src="https://media.diy.fund/intro/benzinga-winner.png" alt="Winner Benzinga Levelling the Playing Field" />
            </div>
          </div>
          <div className="text-align:center" style={{ marginTop: '25px' }}>
            <hr className="intro-line" />
          </div>
          <div className="intro-section row">
            <div className="col-12 offset-sm-2 col-sm-8 mb-3">
              <div className="intro-h1">Best New Investment Platform 2016</div>
              <div className="intro-text">
                {' '}
                DIY.FUND won Best new Investment Platform from{' '}
                <a href="http://www.tradestreaming.com" target="_blank" rel="noopener noreferrer">
                  Tradestreaming
                </a>
                . Tradestreaming is the premier fintech and financial technology publication. We are extremely honored to be part of the best of class for 2016. Learn more{' '}
                <a href="http://www.tradestreaming.com/announcement/the-2016-tradestreaming-awards-winners-best-investing-technology-and-fintech-investors" target="_blank" rel="noopener noreferrer">
                  here.{' '}
                </a>
              </div>
            </div>
            <div className="col-12 text-center">
              <img className="img img-fluid" src="https://media.diy.fund/intro/ts-award-diyfund.png" style={{ maxHeight: '175px' }} alt="Winner Trade Streaming" />
            </div>
          </div>
          <div className="text-align:center" style={{ marginTop: '25px' }}>
            <hr className="intro-line" />
          </div>
          <div className="intro-section row">
            <div className="col-12 offset-sm-2 col-sm-8 offset-md-2 col-md-8">
              <div className="intro-h4 text-center">Get Started Now</div>
              <div className="intro-text text-center">
                {' '}
                DIY.FUND was developed by a team of financial industry veterans. While building portfolio and trading systems for multi-billion dollar hedge funds, we realized the same tools are not
                available to the individual investor...
              </div>
              <div className="intro-text text-center mt-2 mb-2">Until now</div>
            </div>
          </div>
        </div>
      </div>
      <GuestFooter />
    </div>
  );
}

HomePage.propTypes = {};
HomePage.defaultProps = {};

export default HomePage;
