import React from 'react';
import PropTypes from 'prop-types';

function NavLogo(props) {
  const { isFixed } = props;
  return (
    <nav className={`${isFixed ? 'fixed-top' : ''} navbar navbar-expand-lg navbar-light bg-light navlogo`}>
      <div className="container">
        <span className="navbar-brand">
          <img src="https://media.diy.fund/logos/diy-logo-dark.png" className="navlogo-logo" alt="" />
        </span>
      </div>
    </nav>
  );
}

NavLogo.propTypes = {
  isFixed: PropTypes.bool,
};
NavLogo.defaultProps = {
  isFixed: false,
};

export default NavLogo;
