import React from 'react';

function GuestFooter() {
  return (
    <footer>
      <div className="container-fluid intro-footer">
        <div className="container">
          <div className="row">
            <div className="col-6 text-left mt-3">
              <div>© 2023 DIY.FUND</div>
            </div>
            <div className="col-6 text-right mt-2">
              <a className="pointer intro-footerlink" href="https://twitter.com/Diyfund" target="_blank" rel="noopener noreferrer">
                {' '}
                <img src="https://media.diy.fund/intro/twitterlogo-white.png" style={{ height: '30px' }} alt="twitter" />{' '}
              </a>{' '}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default GuestFooter;
